<template>
    <v-app>
        <AppHeader/>
        <div class="main-wrapper">
          <div class="main-contents">
            <router-view></router-view>
          </div>
        </div>
        <AppFooter/>
    </v-app>
</template>

<script>
import AppHeader from './components/AppHeader.vue'
import AppFooter from './components/AppFooter.vue'

export default {
  name: 'index',
  components: {
    AppHeader,
    AppFooter
  }
}
</script>

<style lang="scss">
.main-wrapper {
  @include display_pc {
    border-left: 50px solid #272727;
    border-right: 50px solid #ffffff;
  }
}
.main-contents {
    margin: 56px auto 50px;
    width: 85%;
    @include display_pc {
      margin: 20px auto 50px;
      max-width: 1400px;
      width: 80%;
    }
}
</style>
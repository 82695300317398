<template>
  <v-app class="">
    <div class="">
        <div class="contents-title">
            <div  class="title-link" style="padding-bottom: 1.5rem;">
                <p class="">
                    <a href="#p-design">Product</a>
                </p>
                <p class="title-link-slash">/</p>
                <p class="">
                    <a href="#g-design">Graphic</a>
                </p>
            </div>
            <div class="title-img">
                <img src="../assets/design/title.jpg" alt="">
            </div>
        </div>
    </div>
    <div class="mt-10 mb-10">
        <!-- Product Design -->
        <div id="p-design" class="">
            <!--  Machine Design  -->
            <div class="img-list mb-10">
                <img src="../assets/design/machine-design.jpg" alt="" width="100%">
            </div>
            <!-- Audio Design -->
            <div class="img-list mb-10">
                <img src="../assets/design/audio-design.jpg" alt="" width="100%">
            </div>
        </div>
        <div id="g-design" class="mt-10">
            <!-- Graphic Design -->
            <div class="mt-10">
                <img src="../assets/design/graphic-design.jpg" alt="" width="100%">
            </div>
        </div>
    </div>
  </v-app>
</template>

<script>
// import Designs from '../common/design-page-constans';
export default {
  name: 'Design',
  data () {
      return {
          routes: [
              {
                  name: 'Graphic Design',
                  url: '#g-design'
              },
              {
                  name: 'Product Design',
                  url: '#p-design'
              }
          ]
      }
  },
    head: {
        title() {
            return {
                inner: '有限会社石川デザイン事務所',
                separator: '|',
                complement: 'Design'
            }
        },
        meta() {
            return [
                {name: 'description', content: '有限会社石川デザイン事務所 | Machine Design、Audio Design、Graphic Design'},
                {name: 'keyword', content: 'Machine Design'},
                {name: 'keyword', content: 'Audio Design'},
                {name: 'keyword', content: 'Graphic Design'},
                {name: 'keyword', content: 'Product Graphic'},
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
.v-application a {
    color: #272727 !important;
}
.title-border {
    &-main{
        border-left: solid 5px #7db4e6;/*左線*/
    }
    &-sub {
        border-left: solid 5px #dfe67da6;/*左線*/
        padding: 0 .5em;
    }
    &-right {
        border-right: 15px solid #fbff00
    }
    &-left {
        border-left: 15px solid #4536cc;
    }
}
.contents-title {
    display: flex;
    flex-flow: column;
    @extend .align-center;
    @extend .justify-end;
    @include display_pc {
        display: flex;
        flex-flow: inherit;
    }
    p {
        height: 1.5rem !important;
        padding-right: 1rem !important;
    }
}

.title-link {
    order: 2;
    display: flex;
    justify-content: center;
    p {
        font-size: 1rem;
    }
    @include display_pc {
        order: inherit;
        display: block;
        p {
            font-size: 1.5rem;
        }
    }
}

.title-link-slash {
    display: block;
    @include display_pc {
        display: none;
    }
}

.title-img {
    order: 1;
    img {
        width: 250px;
    }
    @include display_pc {
        order: inherit;
        img {
            width: 300px;
        }
    }
}

.v-item-group {
    display: flex !important;
}

.v-application p {
    height: 16px;
    margin-bottom: 5px !important;
    text-align-last: left;
}

.row {
    justify-content: center !important;
    margin: 20px auto !important;
}

.img_wrap {
    margin: 0 50px !important;
    padding: 20px 0 !important;
}

.sec {
    &-harf {
        width: 50% !important;
    }
    &-border-l {
        border-left: 3px solid #1616085d;
    }
    &-border-r {
        border-right: 3px solid #1616085d;
    }
}

.img-width {
    &-s {
        width: 150px !important;
    }
    &-m {
        width: 200px !important;
    }
    &-l {
        width: 300px !important;
    }
}
</style>
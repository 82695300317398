<template>
  <v-app class="common-font">
    <div class="flex-between title-border-content mb-10">
      <div class="contents-title">
        <div class="company-name">
          <h1 class="ml-1">
            <span class="">有限会社</span> 石川デザイン事務所
          </h1>
        </div>
        <div class="deco-sentence">
          <p>
            disign / catalog・poster・advertising・editorial・logotype・Cl etc
          </p>
          <p>
            various machine・audio・stationery
          </p>
          <p>
            photo / commercial・editorial・car imager・worked product
          </p>
        </div>
      </div>
      <div class="sub-link ml-10 mt-10">
        <div>
          <p class="">
            工業デザイン／グラフィックデザイン／
          </p>
        </div>
        <div>
          <p>コマーシャルフォト／エディトリアル／</p>
        </div>
        <div>
          <p>DDオーディオ・文具デザイン</p>
        </div>
      </div>
      <div class=""></div>
    </div>
    <div class="link-area mt-10 mb-10">
      <v-row class="lighten-4">
        <div class="img_wrap bg-w" v-for="(item, index) in pageItems" :key="index">
          <div class="sp-title">
            <p>{{item.name}}<br><span>{{item.description}}</span></p>
          </div>
          <router-link :to=item.url class="color-b">
            <div>
              <img :src=item.img color=""/>
            </div>
          </router-link>
        </div>
      </v-row>
      <v-row class="lighten-4 margin-0 mb-15">
        <div class="img-desc">
          <div class="bg-w" v-for="(item, index) in pageItems" :key="index">
            <p class="align-center font-s-l link-desc">{{ item.description }}</p>
            <p class="align-center font-w-bold font-s-xxl link-name">{{ item.name }}</p>
          </div>
          <div class="bg-b"></div>
        </div>
      </v-row>
    </div>
  </v-app>
</template>

<script>
import PageRoutes from '../common/page_constans'

export default {
  name: 'Top',
  data () {
    return {
      pageItems: PageRoutes.pageItems
    }
  },
  head: {
    meta() {
      return [
        {name: 'description', content: '有限会社石川デザイン事務所 | 工業デザイン、グラフィックデザイン、コマーシャルフォト、エディトリアル、DDオーディオ・文具デザインを手がけるデザイン事務所です。'},
        {name: 'keyword', content: '有限会社石川デザイン事務所 工業デザイン グラフィックデザイン コマーシャルフォト エディトリアル DDオーディオ・文具デザイン'},
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.title-border {
    &-content {
      border-left: 1px solid #868686;
    }
    &-main {
      border-left: solid 7px #272727;/*左線*/
      padding-bottom: 10px;
      padding-left: 10px;
    }
    &-sub {
      border-left: solid 3px #272727;/*左線*/
      padding-bottom: 15px;
      padding-left: 10px;
      @extend .font-s-s;
      @extend .color-g;
    }
}
.contents-title {
  @extend .justify-between;
}

.company-name {
  h1 {
    @extend .title-border-main;
    @extend .display-inlineblock;
    font-size: 1rem;
    @include display_pc {
      font-size: 1.7rem;
    }
  }
  span {
    @extend .font-w-500;
  }
}

.deco-sentence {
  @include display_pc {
    display: block !important;
  }
  display: none !important;
  @extend .align-right;
  @extend .font-s-xs;
  p {
    margin: .1rem !important;
  }
}

.row {
    justify-content: space-around !important;
    // margin: 20px auto 0;
}

.col-3 {
  max-width: inherit;
}

.sub-link {
  @extend .justify-start;
  @extend .title-border-sub;
  @extend .font-s-xs;
  display: block;
  p {
    margin-bottom: .3rem !important;
  }
  @include display_pc {
    display: flex !important;
    font-size: small;
  }
}

.link-area {
  z-index: 1;
}

.link-desc {
  font-weight: 500;
  color: #818181;
  margin: 10px auto 0 !important;
}
.link-name {
  letter-spacing: .2rem;
  margin: 30px auto 10px !important;
}

.img_wrap {
    margin: 0 0 2rem;
    img {
        width: 220px;
    }
    @include display_pc {
      margin: 0 !important;
    }
}

.sp-title {
  p {
    margin-bottom: 5px !important;
    @extend .font-w-bold;
    @extend .font-s-l
  }
  span {
    font-size: small;
    font-weight: 300;
    padding-left: 1rem;
  }
  @include display_pc {
    display: none !important;
  }
}

.img-desc {
  display: none;
  @include display_pc {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    flex: 1 1 auto;
    margin: -12px;
    .bg-w {
      width: 220px;
    }
  }
}

.bg-b {
  @include display_pc {
    height: 100vh;
    width: 100vw;
    position: absolute;
    // bottom: 0;
    overflow: hidden;
    z-index: -1;
  }
}
</style>
<template>
    <v-footer
    dark
    absolute
    >
      <div class="footer font-w-b font-s-s">
        ©️2021 有限会社石川デザイン事務所 All Rights Reserved.
      </div>
    </v-footer>
</template>

<script>
export default {
    name: 'AppFooter'
}
</script>

<style lang="scss" scoped>
.footer {
  margin: auto;
  text-align: center;
}

.v-footer--absolute {
    @mixin display_sp {
        position: inherit;
    }
}
</style>
<template>
    <header>
      <v-app-bar
        app
        dark
      >
        <v-app-bar-nav-icon @click="drawer = true" right></v-app-bar-nav-icon>
        <v-toolbar-title>
            <router-link to="/" class="color-w link-title" style="font-weight: 300;">
                <span class="font-w-900">I</span>shikawa <span class="font-w-900">D</span>esign
            </router-link>
        </v-toolbar-title>
        <div class="link-area">
          <div
            v-for="(navItem, index) in navItems"
            :key="index"
            class="link-list link-deco"
            :class=navItem.class
          >
            <div class="link-name">
              <router-link :to="navItem.url" class="color-w">
                  {{ navItem.name }}
              </router-link>
            </div>
          </div>
        </div>
      </v-app-bar>
      <v-navigation-drawer
          v-model="drawer"
          fixed
          temporary
      >
          <v-list>
              <v-list-item-group>
                  <v-list-item
                  v-for="(navItem, index) in navItems"
                  :key="index"
                  >
                  <v-list-item-title>
                      <router-link :to="navItem.url" class="sp-nav color-b font-bold">
                          {{ navItem.name }}
                      </router-link>
                  </v-list-item-title>
                  </v-list-item>
              </v-list-item-group>
          </v-list>
      </v-navigation-drawer>
    </header>
</template>

<script>
import headerConstans from '../common/header_constans'

export default {
  name: 'AppHeader',
  data () {
    return {
      drawer: false,
      navItems: headerConstans.navItems
    }
  }
}
</script>

<style lang="scss" scoped>

.v-toolbar__title {
  @include display_pc {
    font-size: 2em !important;
    overflow: visible !important;
    margin-right: 50px !important;
    margin-left: 20px !important;
    padding-left: inherit !important;
  }
}

.v-app-bar__nav-icon {
  @include display_pc {
    display: none !important;
  }
}

.v-tabs {
  display: none;
  @include display_pc {
    display: block !important;
    // margin-left: 40%;
  }
}
.v-app-bar.v-app-bar--fixed {
  position: inherit !important;
}

.link-title {
  @include display_pc {
    font-size: 2.5rem;

  }
}


.link-area {
  display: none;
  @include display_pc {
  display: flex;
  justify-content: flex-end;
  width: -webkit-fill-available;
  height: 64px;
  margin-right: 30px;
  }
}

.link-list {
  height: 64px;
  line-height: 64px;
  margin: 0 10px;
  // transition-duration: 0.5s;
}
.link-name {
  font-size: 1.5rem;

  transition-duration: 0.5s;
}
.link-name:hover {
  transform: scale(1.1);
  // opacity: .7;
  transition-duration: 0.5s;
}

.link-deco {
  padding-left: .8rem;
  &-1 {
    position: relative;
  }
  &-1::before {
    content: '';
    display: inline;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 3px;
    height: 80%;
    background-color: #a55fa1;
    transition-duration: 0.5s;
  }
  &-2 {
    position: relative;
  }
  &-2::before {
    content: '';
    display: inline;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 3px;
    height: 80%;
    background-color: #52919c;
    transition-duration: 0.5s;
  }
  &-3 {
    position: relative;
  }
  &-3::before {
    content: '';
    display: inline;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 3px;
    height: 80%;
    background-color: #cc6e42;
    transition-duration: 0.5s;
  }
  &-4 {
    position: relative;
  }
  &-4::before {
    content: '';
    display: inline;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 3px;
    height: 80%;
    background-color: #b3b86ebd;
    transition-duration: 0.5s;
  }
  &-5 {
    position: relative;
  }
  &-5::before {
    content: '';
    display: inline;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 3px;
    height: 80%;
    background-color: #7abb74;
    transition-duration: 0.5s;
  }
}

.v-toolbar__content {
  padding: 4px 50px !important;
  justify-content: space-between !important;
}

.v-sheet.v-app-bar.v-toolbar:not(.v-sheet--outlined) {
    box-shadow: none;
}

.sp-nav {
  display: block !important;
  width: 100% !important;
  line-height: 3 !important;
}
</style>
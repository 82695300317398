import Vue from 'vue'
import Router from 'vue-router'
import Top from './views/Home.vue'
import Design from './views/Design.vue'
import Photo from './views/Photo.vue'
import Brand from './views/Brand.vue'
import Overview from './views/Overview.vue'
// import pageRoute from './common/page_constans'
Vue.use(Router)

export default new Router({
  // デフォルトの挙動では URL に `#` が含まれる.
  // URL から hash を取り除くには `mode:history` を指定する
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Top',
      component: Top
    },
    {
      path: '/design',
      name: 'Design',
      component: Design
    },
    {
      path: '/photo',
      name: 'Photo',
      component: Photo
    },
    {
      path: '/brand',
      name: 'Brand',
      component: Brand
    },
    {
      path: '/Overview',
      name: 'Overview',
      component: Overview
    }
  ]
})
<template>
    <v-app>
        <div class="contents-title-area">
            <div class="dd-link">
                <a href="https://dedee.jp/" target="_blank">dedee.jp</a>
            </div>
            <div class="contents-title">
                <div class="title-link" style="margin-top: 20px;">
                    <p class="contents-link">
                        Audio & Stationely
                    </p>
                    <p class="contents-link">
                        Design
                    </p>
                </div>
                <div class="title-img">
                    <img src="../assets/brand/title.jpg" alt="" width="250px">
                </div>
            </div>
        </div>
        <div class="mt-10 mb-10">
            <!-- Commercial & Editorial -->
            <div id="p-design" class="">
                <div class="img-list mb-10">
                    <img src="../assets/brand/brand-img.jpg" alt="" width="100%">
                </div>
            </div>
        </div>
    </v-app>
</template>

<script>
export default {
    name: 'Brand',
    head: {
        title() {
            return {
                inner: '有限会社 石川デザイン事務所',
                separator: '|',
                complement: 'Brand'
            }
        },
        meta() {
            return [
                {name: 'description', content: '有限会社石川デザイン事務所 | Audio & Stationely、 Design、Amplifier、Inkserver、PenStand、Blotter'},
                {name: 'keyword', content: 'Audio & Stationely Design'},
                {name: 'keyword', content: 'Amplifier'},
                {name: 'keyword', content: 'Inkserver'},
                {name: 'keyword', content: 'PenStand'},
                {name: 'keyword', content: 'Blotter'}
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
.v-application a {
    color: #272727 !important;
}
.title-border {
    &-main{
        border-left: solid 5px #7db4e6;/*左線*/
    }
    &-sub {
        border-left: solid 5px #dfe67da6;/*左線*/
        padding: 0 .5em;
    }
    &-right {
        border-right: 15px solid #fbff00
    }
    &-left {
        border-left: 15px solid #4536cc;
    }
}

.v-item-group {
    display: flex !important;
}

.v-application p {
    height: 16px !important;
    margin-bottom: 5px !important;
}

.contents-link {
    text-align: right !important;
}
.contents-title-area {
    display: flex;
    flex-flow: column;
    @extend .justify-between;
    @extend .align-item-center;
    @include display_pc {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
    }
}

.dd-link a {
    color: #909727a6 !important;
}
.dd-link {
    order: 2;
    margin-top: 1rem;
    @extend .font-s-xxl;
    @include display_pc {
        order: inherit;
        font-size: xxx-large;
        font-size: -webkit-xxx-large;
    }
}
.contents-title {
    display: flex;
    flex-flow: column;
    order: 1;
    @extend .align-center;
    @extend .justify-end;
    @include display_pc {
        display: flex;
        flex-flow: inherit;
        order: inherit;
    }
}
.title-link {
    order: 2;
    display: flex;
    flex-flow: column;
    @include display_pc {
        order: inherit;
        display: block;
        height: 1.5rem;
        padding-right: 1rem;
    }
    p {
        text-align: center !important;
        font-size: 1rem;
        @include display_pc {
            text-align: right !important;
            font-size: 1.5rem;
        }
    }
}

.title-img {
    order: 1;
    @include display_pc {
        order: inherit;
    }
}
</style>
<template>
    <v-app>
        <div class="contents-title">
            <div class="title-link">
                <p>information</p>
            </div>
            <div class="title-img">
                <img src="../assets/overview/title.jpg" alt="" width="200px">
            </div>
        </div>
        <div class="overview mt-10 mb-10">
            <div id="" class="overview-content">
                <div class="overview-list contact">
                    <dl class="overview-list-title contact-title">
                        <dt>Contact</dt>
                    </dl>
                    <dl class="overview-list-desc contact-desc">
                        <dd><a href="mailto:ishikawa-d@be.to">ishikawa-d@be.to</a></dd>
                    </dl>
                </div>
                <div class="overview-list kaisha">
                    <dl class="overview-list-title kaisha-title">
                        <dt>社名</dt>
                        <dt>所在地</dt>
                        <dt>phone</dt>
                        <dt>fax</dt>
                        <dt>設立</dt>
                        <dt>資本金</dt>
                        <dt>代表</dt>
                        <dt>事業内容</dt>
                    </dl>
                    <dl class="overview-list-desc kaisha-desc">
                        <dd>有限会社 石川デザイン事務所</dd>
                        <dd>〒158-0098 東京都世田谷区上用賀3-8-10</dd>
                        <dd>03-5491-4136</dd>
                        <dd>03-5491-4137</dd>
                        <dd>2005年5月</dd>
                        <dd>300万円</dd>
                        <dd>石川 康彦</dd>
                        <dd>工業デザイン・グラフィックデザイン・広告写真・イラストほか</dd>
                    </dl>
                </div>
                <div class="overview-list raireki">
                    <dl class="overview-list-title raireki-title">
                        <dt>1982年</dt>
                        <dt>1986年</dt>
                        <dt>2005年</dt>
                        <dt>2010年</dt>
                        <dt>2016年</dt>
                    </dl>
                    <dl class="overview-list-desc raireki-desc">
                        <dd>石川デザイン事務所設立</dd>
                        <dd>KSPインキュベーター・KSPCIデザインなど</dd>
                        <dd>有限会社 石川デザイン事務所設立</dd>
                        <dd>AZIS立ち上げ</dd>
                        <dd>DDブランド発足</dd>
                    </dl>
                </div>
                <div class="overview-list jinbutsu">
                    <dl class="overview-list-title jinbutsu-title">
                        <dt>石川 康彦</dt>
                        <dt></dt>
                        <dt></dt>
                    </dl>
                    <dl class="overview-list-desc jinbutsu-desc">
                        <dd>多摩美術大学 大学院修了</dd>
                        <dd>日本写真学園顧問</dd>
                        <dd>多摩美術大学特別講師</dd>
                    </dl>
                </div>
                <div class=" aaa">
                    <div class="overview-list jushou">
                        <dl class="overview-list-title jushou-title">
                            <dd>工業デザイン賞各種多数受賞</dd>
                            <dd>グラフィックデザイン賞各種受賞</dd>
                            <dd>グッドデザイン賞各種受賞</dd>
                            <dd>NYにて写真個展・グループ展</dd>
                            <dd>E＆Cギャラリー 写真個展</dd>
                            <dd>尾道市立美術館・二人展</dd>
                        </dl>
                        <dl class="jushou-desc">
                            <dd>工業デザイン賞各種多数受賞</dd>
                            <dd>グラフィックデザイン賞各種受賞</dd>
                            <dd>グッドデザイン賞各種受賞</dd>
                            <dd>NYにて写真個展・グループ展</dd>
                            <dd>E＆Cギャラリー 写真個展</dd>
                            <dd>尾道市立美術館・二人展</dd>
                        </dl>
                    </div>
                    <div class="overview-list client">
                        <dl class="overview-list-title client-title">
                            <dt style="height: inherit; display: table-row-group;">主なクライアント</dt>
                        </dl>
                        <dl class="overview-list-desc client-desc">
                            <dd>株式会社ソディック</dd>
                            <dd>エンシュウ株式会社</dd>
                            <dd>株式会社スギノマシン</dd>
                            <dd>株式会社放電精密加工研究所</dd>
                            <dd>セーレン株式会社</dd>
                            <dd>株式会社マリン・テック</dd>
                            <dd>ソフトバンク</dd>
                            <dd>日経BP社</dd>
                            <dd>えい出版</dd>
                            <dd>日本カメラ社</dd>
                            <dd>三井物産</dd>
                            <dd>神奈川県</dd>
                        </dl>
                    </div>
                </div>
            </div>
        </div>
    </v-app>
</template>

<script>
export default {
    name: 'Overview',
    head: {
        title() {
            return {
                inner: '有限会社石川デザイン事務所',
                separator: '|',
                complement: 'Information'
            }
        },
        meta() {
            return [
                {name: 'description', content: '有限会社石川デザイン事務所 | 会社概要、来歴、受賞歴、クライアント一覧'},
                {name: 'keyword', content: '会社概要'},
                {name: 'keyword', content: '来歴'},
                {name: 'keyword', content: '受賞歴'},
                {name: 'keyword', content: 'クライアント一覧'},
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
.v-application a {
    color: #272727 !important;
}
.v-application p {
    text-align-last: right !important;
}
.contents-title {
    display: flex !important;
    flex-flow: column;
    @extend .align-center;
    @extend .justify-end;
    @include display_pc {
        display: flex !important;
        flex-flow: row !important;
    }
}
.title-link {
    order: 2;
    display: flex;
    justify-content: center;
    @extend .font-w-bold;
    p {
        font-size: .9rem;
    }
    @include display_pc {
        order: inherit;
        display: block;
        height: 1.5rem;
        padding-right: 1rem;
        margin-top: 1rem;
        p {
            font-size: 1.5rem;
        }
    }
}

.title-link-slash {
    display: block;
    margin: 0 .3rem;
    @include display_pc {
        display: none;
    }
}

.title-img {
    order: 1;
    @include display_pc {
        order: inherit !important;
    }
}

.v-item-group {
    display: flex !important;
}

.v-application p {
    height: 16px !important;
    margin-bottom: 5px !important;
    text-align-last: left;
}

.contents-title {
    display: block;
    @extend .align-center; 
    @extend .justify-end;
    @include display_pc {
        display: flex;
        justify-content: flex-end;
    }
}
.contents-link {
    text-align: right !important;
}

.overview {
    margin: 0 0 0 1rem;
    @include display_pc {
        margin: -7.2rem 0 5rem !important;
    }
}
.overview-list {
    font-size: .6rem;
    @extend .justify-start;
    @include display_pc {
        font-size: 1rem;
        display: flex;
        justify-content: flex-start;
    }
}
.overview-list dt,dd {
    line-height: 2;
}
.overview-list dt {
    width: 3rem;
    @include display_pc {
        width: 4.3rem;
        text-align: right;
    }
}

.overview-list-title {
    padding: 2rem .2rem 2rem 0;
    @include display_pc {
        text-align: right;
        margin-right: 2rem;
        padding: 2rem 2rem 2rem 0;
    }
}
.contact {
    font-weight: bold;
    @include display_pc {
        // font-size: 3rem;
    }
}
.contact-title {
    border-right: 5px solid #95bce0;
    width: 3.5rem;
    padding: 2rem 0;
    @include display_pc {
        border-right: 5px solid #95bce0;
        width: 10rem !important;
        padding: 2.7rem 2rem;
    }
}

.contact-desc {
    padding: 2rem 0 2rem 1rem;
    @include display_pc {
        padding: 2.7rem 0;
    }
}

.kaisha-title {
    border-right: 5px solid #95bce0;
    // width: 3.5rem;
    // padding: 2rem 0;
    @include display_pc {
        width: 8rem !important;
        margin-left: 2rem;
        // padding: 2rem 2rem 2rem 0;
        word-break: inherit;
        text-align: right !important;
    }
}
.kaisha-desc {
    padding: 2rem 0 2rem 1rem;
    @include display_pc {
        padding: 2rem 2rem 2rem 0;
        position: relative;
    }
}
.kaisha-desc::after {
    @include display_pc {
        content: '';
        position: absolute;
        display: inline-block;
        background-color: #95bce0;
        width: 15rem;
        height: 5px;
        bottom: -.1rem;
        left: -2.3rem;
    }
}

.raireki {
    @include display_pc {
        margin-left: 9.7rem;
    }
}
.raireki-title {
    border-right: 5px solid #d67dd2;
    // padding: 0;
    @include display_pc {
        // border-top: 5px solid #95bce0;
        width: 6rem;
        margin-left: 9rem;
        padding-top:2.3rem;
    }
}
.raireki-desc {
    padding: 2rem 0 2rem 1rem;
    @include display_pc {
        padding-top:2rem;
        border-top: 5px solid #ffffff;
        padding-bottom: 3rem;
    }
}
.jinbutsu {
    @include display_pc {
        margin-left: 7.7rem;
    }

}
.jinbutsu-title {
    border-right: 5px solid #74c46d;
    @include display_pc {
        padding-top: 2rem;
    }
}
.jinbutsu-desc {
    padding: 2rem 0 2rem 1rem;
    @include display_pc {
        padding: 2rem 0;
        position: relative;
        display: inline-block;
    }
}
.jinbutsu-desc::before {
    @include display_pc {
        content: '';
        position: absolute;
        display: inline-block;
        background-color: #74c46d;
        width: 10.6rem;
        height: 5px;
        top: -.1rem;
        left: -2.2rem;
    }
}
.jinbutsu-desc::after {
    @include display_pc {
        content: '';
        position: absolute;
        display: inline-block;
        background-color: #74c46d;
        width: 10.6rem;
        height: 5px;
        bottom: 0;
        left: -2rem;
    }
}
.aaa {
    @include display_pc {
        position: relative;
    }
}
.jushou {
    @include display_pc {
        margin-left: 7.7rem;
        // position: relative;
    }
}
.jushou-title {
    display: none;
    @include display_pc {
        display: block;
        border-right: 5px solid #d89d59;
        padding-top: 5rem;
        margin-right: 0;
    }
}
.jushou-desc {
    display: block;
    border-left: 5px solid #d89d59;
    margin-left: 3.2rem;
    padding: 2rem 0 2rem 1rem;
    @include display_pc {
        display: none;
        width: 50%;
        position: relative;
    }
}
.jushou-title::after {
    @include display_pc {
        content: "";
        position: absolute;
        display: inline-block;
        background-color: #ffffff;
        width: 39rem;
        border-top: 5px solid #8d8d8d;
        border-right: 5px solid #8d8d8d;
        height: 6.5rem;
        bottom: -6.5rem;
        left: 24.65rem;
    }
}

.client {
    @include display_pc {
        position: absolute;
        bottom: 0;
        // right: 5rem;
        left:60%;
        // width: 25rem;
    }
}
.client-title {
    border-right: 5px solid #89a86f;
    writing-mode: vertical-rl;
    padding: 2rem 1.95rem 0 0;
    @include display_pc {
        border-right: 5px solid #89a86f;
        writing-mode: vertical-rl;
        padding: 2rem 2rem 0 0;
    }
}
.client-desc {
    padding: 2rem 0 2rem 1rem;
    @include display_pc {
        padding: 2rem 0;
        dd {
            width: 20rem !important;
        }
    }
}
</style>
export default {
    navItems: [
        {
            name: 'Home',
            class: 'link-deco-1',
            url: '/'
        },
        {
            name: 'Design',
            class: 'link-deco-2',
            url: '/design'
        },
        {
            name: 'Photo',
            class: 'link-deco-3',
            url: '/photo'
        },
        {
            name: 'Brand',
            class: 'link-deco-4',
            url: '/brand'
        },
        {
            name: '概要',
            class: 'link-deco-5',
            url: '/overview'
        }
        // {
        // name: 'コンタクト',
        // url: '#'
        // }
    ]
}
export default {
    pageItems: [
        {
            name: 'Design',
            description: 'graphic & product',
            url: '/design',
            img: require('@/assets/home/design.jpg')
        },
        {
            name: 'Photo',
            description: 'commercial & editorial',
            url: '/photo',
            img: require('@/assets/home/photo.jpg')
        },
        {
            name: 'Brand',
            description: 'stationery & audio',
            url: '/brand',
            img: require('@/assets/home/brand.jpg')
        }
    ]
}
<template>
    <v-app>
        <div class="">
        <div class="contents-title">
            <div class="title-link" style="margin-top: 15px;">
                <p class="contents-link">
                    <a href="#p-design">Commercial & Editorial••••</a>
                </p>
                <p class="font-s-15 title-link-slash"></p>
                <p class="contents-link">
                    <a href="#g-design">and Private</a>
                </p>
            </div>
            <div class="title-img">
                <img src="../assets/photo/title.jpg" alt="" width="250px">
            </div>
        </div>
        </div>
        <div class="mt-10 mb-10">
            <!-- Commercial & Editorial -->
            <div id="p-design" class="">
                <div class="img-list mb-10">
                    <p class="font-s-l">Commercial & Editorial</p>
                    <div class="mt-5 img-content">
                        <div>
                            <img src="../assets/photo/commercial-editorial.jpg" alt="" width="100%">
                        </div>
                        <div class="dl-link nsw-dl">
                            <a class="color-w" href=./nsw.pdf download="nsw.pdf">NSW<br>Download</a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Private work -->
            <div id="g-design" class="mt-15">
                <div class="mt-10">
                    <p class="font-s-l">Private work 「樹態告知」</p>
                    <div class="mt-5 img-content">
                        <div>
                            <img src="../assets/photo/personal-work.jpeg" alt="" width="100%">
                        </div>
                        <div class="dl-link private-dl">
                            <a class="color-w" href="./樹態告知.pdf" download="樹態告知.pdf">樹態告知<br>Download</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </v-app>
</template>

<script>
export default {
    name: 'Photo',
    data () {
        return {
            pdfLink: '../../public/nsw.pdf'
        }
    },
    head: {
        title() {
            return {
                inner: '有限会社石川デザイン事務所',
                separator: '|',
                complement: 'Photo'
            }
        },
        meta() {
            return [
                {name: 'description', content: '有限会社石川デザイン事務所 | Commercial & Editorial•••• and Private'},
                {name: 'keyword', content: 'Commercial & Editorial•••• and Private'},
                {name: 'keyword', content: 'Commercial & Editorial'},
                {name: 'keyword', content: 'Private'},
                {name: 'keyword', content: 'NSW Download'},
                {name: 'keyword', content: '樹態告知 Download'}
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
.v-application a {
    color: #272727;
}
.contents-link {
    text-align: right !important;
}
.contents-title {
    display: flex;
    flex-flow: column;
    @extend .align-center;
    @extend .justify-end;
    @include display_pc {
        display: flex !important;
        flex-flow: inherit !important;
    }
}
.title-link {
    order: 2;
    display: flex;
    justify-content: center;
    p {
        font-size: .9rem;
    }
    @include display_pc {
        order: inherit;
        display: block;
        height: 1.5rem;
        padding-right: 1rem;
        p {
            font-size: 1.5rem;
        }
    }
}

.title-link-slash {
    display: block;
    margin: 0 .3rem;
    @include display_pc {
        display: none;
    }
}

.title-img {
    order: 1;
    @include display_pc {
        order: inherit !important;
    }
}
.v-item-group {
    display: flex !important;
}

.v-application p {
    height: 16px;
    margin-bottom: 5px !important;
    // text-align-last: left;
}

.dl-link {
    display: inline-block;
    float: right;
    @include display_pc {
        position: absolute !important;
    }
}

.dl-link.nsw-dl {
    bottom: .45%;
    right: 25%;
}

.nsw-dl {
    @extend .bg-g;
    padding: .5em 2.5em .5em 1em;
    line-height: normal;
}

.dl-link.private-dl {
    top: 20%;
    right: -3%;
}

.private-dl {
    @extend .bg-g;
    padding: .5em 2.5em .5em 1em;
    @include display_pc {
        writing-mode: vertical-rl;
        padding: 1.5em .3em 3em .3em;
        line-height: normal;
    }
}

.img-content {
    @include display_pc {
        position: relative !important;
    }
}
</style>